import React from "react";
import ContactButton from "../../components/contactButton";
import HeaderDivideTextLeft from "../../components/headerDivideTextLeft";
import Layout from "../../components/layout";
import Row from "starkeydigital/React/Components/row";
import Accordion from "starkeydigital/React/Components/accordion";
interface props {}
const MeetingFacilitation: React.FC<props> = (props) => {
  return (
    <Layout
      titleText="Meeting Facilitation"
      titleText2=""
      slogan="We facilitate leadership meetings enabling the chair or leader to focus on the key decisions."
    >
      <HeaderDivideTextLeft
        style={{marginTop: "115px"}}
        column="col-md-10"
        header="Meeting Facilitation"
        text="We facilitate Board Meetings using our expertise to understand the human interaction needed to produce the desired business output whatever the content of the meeting."
      />

      <Row>
        <div className="col mt-4">
          <ContactButton>Ready to work together?</ContactButton>
        </div>
      </Row>

      <Row style={{marginTop: "60px"}}>
        <div className="col">
          <Accordion>
            <Accordion.Item title="Why would you invest in Board Facilitation?">
              To ensure a meeting achieves its goals in the time available / To
              allow all those involved in the meeting to contribute fully / To
              refocus and enliven meetings that have become stale and
              unproductive / To ensure that a newly formed group becomes
              effective quickly and establishes productive ways of working
              together / To relieve the person running the meeting from having
              to deal with the ‘process’ thus allowing them to contribute fully
              / To increase creativity in meetings
            </Accordion.Item>

            <Accordion.Item title="Who would benefit from Board Facilitation?">
              Those who want to get involved in a meeting rather than having to
              ‘hold’ the process and effectiveness of the meeting / Those
              planning large meetings / Those dissatisfied with the outcomes of
              meetings
            </Accordion.Item>

            <Accordion.Item title="When would Board Facilitation work for you?">
              At the planning stage of ‘one off’ meetings / When regular
              meetings are not achieving their goals in the time available /
              When a new person is taking responsibility for meetings
            </Accordion.Item>
          </Accordion>
        </div>
      </Row>
    </Layout>
  );
};
export default MeetingFacilitation;
